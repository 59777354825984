import RestApi, { warehouseServiceBaseUrl } from '../config/api_config'
export default {
  computed: {
    hasDropdownLoadedWarehouse () {
      return this.$store.state.warehouse.hasDropdownLoaded
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    }
  },
  watch: {
    hasDropdownLoadedWarehouse: function (newValue) {
      if (!newValue) {
        this.loadDropdownWarehouse()
      }
    }
  },
  created () {
    if (!this.hasDropdownLoadedWarehouse || window.performance) {
      this.loadDropdownWarehouse()
    }
  },
  methods: {
    loadDropdownWarehouse () {
      RestApi.getData(warehouseServiceBaseUrl, 'warehouse-dropdown-list-all', null).then(response => {
        if (response.status_code === 200) {
          const fieldOfficerFilterData = this.getFieldOfficerFilterData2(response.data)
          this.$store.commit('mutateWarehouseProperties', {
            hasDropdownLoaded: true,
            commodityGroupList: response.data.commodityGroupList,
            commodityNameList: response.data.commodityNameList,
            regionList: fieldOfficerFilterData.regionList,
            fiscalYearList: this.fiscalYearBnAdd(response.data.fiscalYearList),
            warehouseDesignationList: response.data.warehouseDesignationList,
            warehouseTypeList: fieldOfficerFilterData.warehouseTypeList,
            warehouseInfoList: fieldOfficerFilterData.warehouseInfoList,
            warehouseLevelList: response.data.warehouseLevelList,
            warehouseCategoryList: fieldOfficerFilterData.warehouseCategoryList,
            serviceTypeList: response.data.serviceTypeList,
            marketInfoList: response.data.marketInfoList,
            regionDetailList: response.data.regionDetailList
            // marketInfoList: response.data.marketInfoList
          })
          this.$store.dispatch('changeWarehouseDropdown', { value: this.$i18n.locale, that: this })
        }
      })
    },
    getFieldOfficerFilterData2 (data) {
      const filteredData = {
        regionList: data.regionList,
        warehouseTypeList: data.warehouseTypeList,
        warehouseCategoryList: data.warehouseCategoryList,
        warehouseInfoList: data.warehouseInfoList
      }
      if (!this.isWareHouseUser) {
        return filteredData
      }
      const warehouse = data.warehouseInfoList.find(item => item.office_id === this.authUser.office_detail.id)
      if (typeof warehouse !== 'undefined') {
        filteredData.regionList = data.regionList.filter(item => item.value === warehouse.region_id)
        filteredData.warehouseTypeList = data.warehouseTypeList.filter(item => item.value === warehouse.warehouse_type_id)
        filteredData.warehouseCategoryList = data.warehouseCategoryList.filter(item => item.value === warehouse.warehouse_category_id)
        filteredData.warehouseInfoList = data.warehouseInfoList.filter(item => item.value === warehouse.value)
      }
      return filteredData
    },
    fiscalYearBnAdd (data) {
      const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
      const bnAdded = data.map(item => {
        const fiscalYearData = {}
        const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
        fiscalYearData.text_bn = result.join('')
        return Object.assign({}, item, fiscalYearData)
      })
      return bnAdded
    }
  }
}
